import { atom } from 'recoil';

export const themeLogo = atom({
  key: 'themeLogo',
  default: 'dark',
});

export const themeLogin = atom({
  key: 'themeLogin',
  default: 'dark',
});

export const themeFooter = atom({
  key: 'themeFooter',
  default: 'dark',
});

export const showLogin = atom({
  key: 'showLogin',
  default: true,
});

export const showLogo = atom({
  key: 'showLogo',
  default: true,
});
