import { useState, useEffect } from 'react';
import { useCookies } from 'react-cookie';
import addDays from 'date-fns/addDays';

const COOKIE_ANONYMOUS_USER_KEY = 'zonky-ab-anonymous-user-key';

// generates key in a format "A-[0-99]"
const generateAnonymousUserKey = function () {
  return `A-${Math.floor(Math.random() * 100)}`;
};

export function useLDAnonymousKey() {
  const [cookies, setCookie] = useCookies([COOKIE_ANONYMOUS_USER_KEY]);
  const [anonymousUserKey, setAnonymousUserKey] = useState(
    cookies[COOKIE_ANONYMOUS_USER_KEY]
  );

  useEffect(() => {
    if (!anonymousUserKey) {
      const newAnonymousKey = generateAnonymousUserKey();
      const expires = addDays(new Date(), 365);
      setCookie(COOKIE_ANONYMOUS_USER_KEY, newAnonymousKey, {
        path: '/',
        expires,
      });
      setAnonymousUserKey(newAnonymousKey);
    }
  }, [anonymousUserKey, setCookie]);

  return anonymousUserKey;
}
