import React, { createContext, useContext, useEffect, useState } from 'react';
import { AuthContext } from './AuthContext';
import { useLDAnonymousKey } from '../hooks/useLDAnonymousKey';

const FeaturesContext = createContext(null);

export default FeaturesContext;

export const FeaturesProvider = ({ client, children }) => {
  const { user } = useContext(AuthContext);
  const [state, setState] = useState(null);
  const anonymousUserKey = useLDAnonymousKey();

  useEffect(() => {
    if (client && anonymousUserKey) {
      let context = { kind: 'user', key: anonymousUserKey, anonymous: true };

      if (user) {
        context.key = `${user.id}`;
        context.anonymous = false;
      }

      client.identify(context).then((state) => {
        setState(state);
      });
    }
  }, [client, user, anonymousUserKey]);

  return (
    <FeaturesContext.Provider value={state}>
      {children}
    </FeaturesContext.Provider>
  );
};
