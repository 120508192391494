import React, { Component } from 'react';
import { TrackJS } from 'trackjs';

import { ErrorPage } from '../shared/ErrorPage';

const isProd = process.env.NODE_ENV === 'production';
const token = process.env.GATSBY_TRACKJS_TOKEN;

export default class ErrorBoundary extends Component {
  state = {
    error: null,
  };

  static getDerivedStateFromError(error) {
    return { error };
  }

  componentDidMount() {
    if (isProd) {
      TrackJS.install({
        token,
        application: 'osobni-skore',
      });
    }
  }

  componentDidCatch(error, errorInfo) {
    if (isProd) {
      TrackJS.track({ error, errorInfo });
    }
  }

  resetError = () => {
    this.setState({ error: null });
  };

  render() {
    if (this.state.error) {
      return (
        <ErrorPage
          className="absolute top-0 right-0 bottom-0 left-0 h-full w-full flex flex-col justify-center items-center bg-white z-50"
          onClick={this.resetError}
          seoTitle="Runtime error"
          title="Něco se pokazilo"
          description="Došlo k neočekávané chybě."
        />
      );
    }

    return this.props.children;
  }
}
