import React, { useState, useEffect } from 'react';
import { navigate } from 'gatsby';

const defaultState = {
  globalError: null,
};

export const ErrorContext = React.createContext(defaultState);

export const ErrorProvider = ({ children }) => {
  const [globalError, setGlobalError] = useState(null);

  useEffect(() => {
    if (globalError) {
      console.error(globalError);
      const { isUnauthorized, isForbidden, isNotFound, isServiceUnavailable } =
        globalError;

      if (isUnauthorized) {
        navigate('/ups/401');
      } else if (isForbidden) {
        navigate('/ups/403');
      } else if (isNotFound) {
        navigate('/ups/404');
      } else if (isServiceUnavailable) {
        navigate('/ups/503');
      } else {
        navigate('/ups/500');
      }
    }
  }, [globalError]);

  return (
    <ErrorContext.Provider value={{ globalError, setGlobalError }}>
      {children}
    </ErrorContext.Provider>
  );
};
